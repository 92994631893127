<script>
export default {
    page: {title: 'Συχνές ερωτήσεις'}
}
</script>
<style lang="scss">
    #faq{
        a{
            display:block;
            padding-bottom: 1rem;
            font-weight: bold;
            font-size: 1.1rem;
        }
    }

</style>

<template>
    <div id="faq-layout">
        <div id="faq" class="row">
            <div class="card col-md-3 shadow-sm-">
                <div class="text-center mt-4">
                    <span class="h1 icon-question text-muted"></span>
                    <h3 class="mt-4 text-uppercase font-weight-bold">ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ</h3>
                    <p>Συχνές ερωτήσεις και επεξήγηση βασικών λειτουργιών</p>

                    <p class="pt-3"></p>
                    <a href="#pal">Συντελεστές PAL</a>
                    <a href="#BMR">Βασικός Μεταβολισμός</a>
                    <a href="#useExercise">Προχωρημένη εξίσωση</a>
                    <a href="#manualKcal">Χειροκίνητες θερμίδες</a>
                    <a href="#manualBMR">Χειροκίνητο BMR</a>
                    <a href="#vegan">Vegan</a>
                    <a href="#fasting">Νηστεία</a>
                    <a href="#carbohydration">Υδατανθράκωση</a>
                    <a href="#supplements">Συμπληρώματα</a>
                </div>
            </div>
            <div class="card col-md-9 shadow-sm" style="max-height:800px;overflow-y:scroll;max-width: 100%;">
                <div class="row pt-5 p-5">
                    <div id="pal">
                        <h2 class="pb-2">Συντελεστές Pal </h2>
                        <p class="text-muted">
                            Για την εκτίμηση των ενεργειακών αναγκών ασθενών τρεις δυο οι κύριες συνιστώσες: α) η εκτίμηση του βασικού μεταβολικού ρυθμού, β) η ενεργειακή κατανάλωση λόγω σωματικής δραστηριότητας και η θερμογένεση λόγω κατανάλωσης τροφής.
                        </p>
                        <p class="text-muted">
                            Μετά την εκτίμηση του βασικού μεταβολικού ρυθμού ηρεμίας, οι συνολικές ενεργειακές ανάγκες των ενηλίκων υπολογίζονται πολλαπλασιάζοντας τον μεταβολικό ρυθμό ηρεμίας με το
                            κατάλληλο επίπεδο φυσικής δραστηριότητας
                            (Physical Activity Level, PAL), σύμφωνα με τα παρακάτω (Report of a Joint FAO/WHO/UNU Expert Consultation, 2001): </p>
                        <p class="text-muted"><strong>
                            - Καθιστικός ή ελαφρά δραστήριος τρόπος ζωής: PAL: 1,40–1,69<br>
                            - Έντονα δραστήριος τρόπος ζωής: PAL: 2,00–2,40<br>
                            - Τιμές του PAL > 2,40 είναι δύσκολο να διατηρηθούν για μεγάλο χρονικό διάστημα.
                        </strong>
                        </p>
                        <p class="text-muted">
                            Συνοπτικά, τα άτομα που κατατάσσονται στην κατηγορία της ελαφριάς δραστηριότητας ή που είναι λιγότερο δραστήρια από τον μέσο όρο, είναι παιδιά ή έφηβοι που
                            περνούν πολλές ώρες στο σχολείο ή σε καθιστικές δραστηριότητες, δεν αθλούνται συστηματικά, μετακινούνται κυρίως με οχήματα και στις ελεύθερες ώρες τους ασχολούνται με δραστηριότητες, όπως η
                            τηλεθέαση, το διάβασμα και ο ηλεκτρονικός υπολογιστής. Αντίθετα, τα άτομα με έντονο τρόπο ζωής ή με μεγαλύτερη δραστηριότητα από τον μέσο όρο είναι παιδιά και έφηβοι που περπατούν καθημερινά
                            μεγάλες αποστάσεις ή που χρησιμοποιούν ποδήλατο για τη μεταφορά τους, που ασχολούνται για αρκετές ώρες με δραστηριότητες
                            οι οποίες απαιτούν μεγάλα ποσά ενέργειας, και/ή με αθλήματα που απαιτούν υψηλό επίπεδο σωματικής προσπάθειας για αρκετές ώρες, αρκετές ημέρες την εβδομάδα.
                        </p>
                        <img src="https://edlservices.s3.eu-north-1.amazonaws.com/PAL.jpg" alt="pal"/>
                    </div>
                    <div id="BMR" class="pt-3">
                        <h2 class="pb-2">Βασικός Μεταβολισμός (ΒΜR) </h2>
                        <p class="text-muted">
                            Υπάρχουν διάφορες εξισώσεις που μας βοηθάνε να υπολογίσουμε τον βασικό μας μεταβολισμό, δηλαδή την ενέργεια που χρειαζόμαστε για να πραγματοποιήσουμε τις ακούσιες λειτουργίες του οργανισμού μας (αναπνοή, πέψη, καρδιακή λειτουργία κ.ά.)
                            εφόσον βρισκόμαστε ξαπλωμένοι σε ύπτια θέση
                        </p>
                        <div class="p-3">
                            <h4>Miffin</h4>
                            <p class="text-muted w-75">
                                Για τους μη νοσηλευόμενους ασθενείς η εκτίμηση μεταβολικού ρυθμού ηρεμίας γίνεται με βάση τις προαναφερόμενες εξισώσεις (Πίνακας 7.1). Ωστόσο, η εκτίμηση των ενεργειακών αναγκών παχύσαρκων ατόμων παραμένει δύσκολη και, σύμφωνα με μελέτες εγκυρότητας, φαίνεται πως οι εξισώσεις Mifflin (Mifflin et al., 1990) εκτιμούν με μεγαλύτερη ακρίβεια τον βασικό μεταβολικό ρυθμό παχύσαρκων ατόμων
                                (D. C. Frankenfield, Rowe, Smith, & Cooney, 2003).
                            </p>
                            <p class="text-muted w-75">
                                Αν ο ΒΜΙ σας είναι μέχρι και 25 χρησιμοποιήστε την παρακάτω εξίσωση <strong>(Harris & Benedict)</strong>

                            </p>
                            <h4>Harris-Benedict</h4>
                            <p class="text-muted w-75">
                                Πως αξιολογείται: Η εξίσωση αυτή είναι η παλαιότερη που υπάρχει και έχει ελεγχθεί ως προς την ικανότητα πρόβλεψης του μεταβολικού ρυθμού ηρεμίας σε εύρη βάρους 25-124,9 kg,
                                σε εύρη ύψους 151-200 cm και σε ηλικιακό εύρος 21-70 ετών. Έχει χρησιμοποιηθεί επίσης και για την εκτίμηση ενεργειακών αναγκών σε παθολογικές καταστάσεις πολλαπλασιάζοντας με τον
                                αντίστοιχο παράγοντα στρες (1,2-1,8 ανάλογα με τη βαρύτητα και της φύσης της νόσου). Η συγκεκριμένη εξίσωση δεν έχει μελετηθεί στο βαθμό που απαιτείται σε ηλικιωμένα άτομα, στα οποία
                                φαίνεται ότι μάλλον υποεκτιμά το RMR (τόσο σε άνδρες όσο και σε γυναίκες).
                               </p>
                            <p class="text-muted w-75">
                                Η αρχική μορφή της, επίσης, τείνει να υπερεκτιμά γενικά το RMR σε ποσοστό έως και 42%.
                            </p>
                            <p class="text-muted w-75">
                                Η αναθεωρημένη μορφή της φαίνεται ότι εκτιμά το RMR με ακρίβεια ± 14% σε άτομα που βρίσκονται σε καλά επίπεδα θρέψης, κάτι που δεν ισχύει στις περιπτώσεις ατόμων με υποθρεψία.

                            </p>
                            <p class="text-muted w-75">
                                <strong>Χρησιμότητα</strong><br>
                                Η εξίσωση Harris-Benedict μπορεί να χρησιμοποιείται κυρίως σε νεαρά ενήλικα άτομα (κάτω των 65 ετών) φυσιολογικού σωματικού βάρους και σε κλινικές-παθολογικές καταστάσεις
                                χρησιμοποιώντας τον κατάλληλο παράγοντα στρες.

                            </p>
                        </div>

                    </div>
                    <div id="useExercise" class="pt-3">
                        <h2 class="pb-2">Προχωρημένη εξίσωση; (χρήση αθλημάτων για υπολογισμό ενέργειας ανά ημέρα)</h2>
                        <p class="text-muted">
                            Στην παρακάτω λειτουργία αφού την επιλέξετε, πλέον ο προσδιορισμός της ενέργειας δεν θα γίνεται με τις εξισώσεις των PAL κατά προσέγγιση, αλλά με την χρήση
                            πραγματικών αθλητικών δραστηριοτήτων. </p>
                        <p class="text-muted">
                            Με  βάση το PAL των καθημερινών δραστηριοτήτων, ρυθμίζεται με την μπάρα από το 1-1,39 την γενική δραστηριότητα της ημέρας (βάδισμα, πλύσιμο δοντιών, εργασία, καθημερινά απαραίτητες
                            λειτουργίες κ.α) και έπειτα μεταφερθείτε στο πεδίο της άσκησης, όπου επιλέγοντας την εκάστοτε ημέρα τι άθλημα ή φυσική δραστηριότητα θα πραγματοποιηθεί σε συγκεκριμένο χρόνο
                            διάστημα θα γίνει ο υπολογισμός των ημερήσιων ενεργειακών αναγκών για το χτίσιμο του πλήρους, εξατομικευμένου διαιτολογίου.
                        </p>

                    </div>

                    <div id="manualKcal" class="pt-3">
                        <h2 class="pb-2">Εισαγωγή θερμίδων χειροκίνητα;</h2>
                        <p class="text-muted">
                            Επιλέγοντας αυτή την λειτουργία το πρόγραμμα θα σας βοηθήσει να δημιουργήσετε ένα εβδομαδιαίο διατροφικό πλάνο με βάση των συγκεκριμένων θερμίδων που επιθυμείτε.  </p>
                    </div>

                    <div id="manualBMR" class="pt-3">
                        <h2 class="pb-2">Εισαγωγή βασικού μεταβολισμού (BMR) χειροκίνητα;</h2>
                        <p class="text-muted">
                            Επιλέγοντας αυτή την λειτουργία δεν θα χρειαστεί να επιλέξετε κάποια εξίσωση υπολογισμού για τον βασικό μεταβολισμό, αλλά θα γίνει χειροκίνητα, ανάλογα με αποτέλεσμα που έχετε βρει από κάποιο μηχάνημα (πχ έμμεση θερμιδομετρία )
                        </p>
                    </div>

                    <div id="vegan" class="pt-3">
                        <h2 class="pb-2">Vegan διαιτολόγια </h2>
                        <p class="text-muted">
                            Κατά αυτήν την λειτουργεία το σύστημα θα σας εμφανίζει τρόφιμα που είναι μονάχα για βίγκαν και αν είχατε ένα πρότυπο διατροφικό πλάνο (η οπουδήποτε διατροφικό πλάνο)
                            και το εισάγεται, στην περίπτωση που υπάρχει κάποιο μην βίγκαν τρόφιμο,
                            θα το διαγράψει εμφανώς με στόχο την αντικατάσταση του</p>
                    </div>

                    <div id="fasting" class="pt-3">
                        <h2 class="pb-2">Νηστεία </h2>
                        <p class="text-muted">
                            Κατά αυτήν την λειτουργεία το σύστημα θα σας εμφανίζει τρόφιμα που είναι μονάχα για την νηστεία και αν είχατε ένα πρότυπο διατροφικό πλάνο (η οπουδήποτε διατροφικό πλάνο) και το εισάγεται,
                            στην περίπτωση που υπάρχει κάποιο μην νηστίσιμο τρόφιμο, θα το διαγράψει εμφανώς με στόχο την αντικατάσταση του. </p>
                    </div>

                    <div id="carbohydration" class="pt-3">
                        <h2 class="pb-2">Υδατανθράκωση </h2>
                        <p class="text-muted">
                            Επιλέγοντας την υδατανθράκωση, θα σας εμφανίσει σε ποια ημέρα θα επιθυμούσατε να πραγματοποιηθεί το πρωτόκολλο. Το σύγχρονο πρωτόκολλο της υδατανθράκωσης είναι : 10γρ υδατανθράκων /kg
                            σωματικού βάρους. Αφού επιλέξετε την ημέρα που θα θέλατε να εφαρμοστεί (μια μέρα πριν το μεγάλο αγώνα ή προπόνηση), όταν πάτε για την δημιουργία της ημέρας, οι ενεργειακές και
                            μακροθρεπτικές ανάγκες θα είναι έτοιμα
                            προσαρμοσμένες με τα σωματομετρικά χαρακτηριστικά του ασκούμενου για την δόμηση του διαιτολογίου. </p>
                    </div>

                    <div id="supplements" class="pt-3">
                        <h2 class="pb-2">Συμπληρώματα  </h2>
                        <p class="text-muted">
                            Αφού επιλέξετε τι άθλημα κάνει ο εκάστοτε πελάτης σας, το athlisis σας κάνει προτάσεις συμπληρωμάτων με βάση την διεθνή βιβλιογραφία αθλητικής διατροφής για πριν, κατά την διάρκεια και μετά την άσκηση.
                              </p>
                        <p class="text-muted">
                            Δεν χρειάζεται να τα δώσετε όλα τα συμπληρώματα που προτείνονται, αλλά να επιλέξετε με βάση, την ηλικία, τους στόχους, τις απαιτήσεις και την ιδιοσυγκρασία του πελάτη σας.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
